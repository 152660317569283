let classes = [
  {
    name: "GeP 22-6",
    blocks: [
      { start: "2022-08-18", end: "2022-08-26" },
      { start: "2022-09-19", end: "2022-10-07" },
      { start: "2022-11-01", end: "2022-11-04" },
      { start: "2022-11-28", end: "2022-12-21" },
      { start: "2023-01-09", end: "2023-01-17" },
      { start: "2023-03-27", end: "2023-04-21" },
      { start: "2023-05-30", end: "2023-07-06" },
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
    ],
  },
  {
    name: "GeP 22-7",
    blocks: [
      { start: "2022-08-18", end: "2022-08-26" },
      { start: "2022-09-19", end: "2022-10-07" },
      { start: "2022-11-01", end: "2022-11-04" },
      { start: "2022-11-28", end: "2022-12-21" },
      { start: "2023-01-09", end: "2023-01-17" },
      { start: "2023-03-27", end: "2023-04-21" },
      { start: "2023-05-30", end: "2023-07-06" },
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
    ],
  },
  {
    name: "GeP 22-8",
    blocks: [
      { start: "2022-08-18", end: "2022-09-16" },
      { start: "2022-11-07", end: "2022-11-25" },
      { start: "2023-01-18", end: "2023-01-26" },
      { start: "2023-02-01", end: "2023-03-03" },
      { start: "2023-04-24", end: "2023-05-12" },
      { start: "2023-05-22", end: "2023-05-26" },
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-05-23" },
    ],
  },
  {
    name: "GeP 22-9",
    blocks: [
      { start: "2022-08-18", end: "2022-08-26" },
      { start: "2022-09-19", end: "2022-10-07" },
      { start: "2022-11-01", end: "2022-11-04" },
      { start: "2022-11-28", end: "2022-12-21" },
      { start: "2023-01-09", end: "2023-01-17" },
      { start: "2023-03-27", end: "2023-04-21" },
      { start: "2023-05-30", end: "2023-07-06" },
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
    ],
  },
  {
    name: "GeP 22-10",
    blocks: [
      { start: "2022-08-18", end: "2022-09-16" },
      { start: "2022-11-07", end: "2022-11-25" },
      { start: "2023-01-18", end: "2023-01-26" },
      { start: "2023-02-01", end: "2023-03-03" },
      { start: "2023-04-24", end: "2023-05-12" },
      { start: "2023-05-22", end: "2023-05-26" },
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-05-23" },
    ],
  },
  {
    name: "GeP 22-11",
    blocks: [
      { start: "2022-08-18", end: "2022-09-16" },
      { start: "2022-11-07", end: "2022-11-25" },
      { start: "2023-01-18", end: "2023-01-26" },
      { start: "2023-02-01", end: "2023-03-03" },
      { start: "2023-04-24", end: "2023-05-12" },
      { start: "2023-05-22", end: "2023-05-26" },
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-05-23" },
    ],
  },
  {
    name: "GeP 22-12",
    blocks: [
      { start: "2022-08-18", end: "2022-08-26" },
      { start: "2022-09-19", end: "2022-10-07" },
      { start: "2022-11-01", end: "2022-11-04" },
      { start: "2022-11-28", end: "2022-12-21" },
      { start: "2023-01-09", end: "2023-01-17" },
      { start: "2023-03-27", end: "2023-04-21" },
      { start: "2023-05-30", end: "2023-07-06" },
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
    ],
  },
  {
    name: "GeP 22-13",
    blocks: [
      { start: "2022-08-18", end: "2022-08-26" },
      { start: "2022-09-19", end: "2022-10-07" },
      { start: "2022-11-01", end: "2022-11-04" },
      { start: "2022-11-28", end: "2022-12-21" },
      { start: "2023-01-09", end: "2023-01-17" },
      { start: "2023-03-27", end: "2023-04-21" },
      { start: "2023-05-30", end: "2023-07-06" },
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
    ],
  },

  {
    name: "GeP 23-3 F",
    blocks: [
      { start: "2023-02-01", end: "2023-03-03" },
      { start: "2023-04-24", end: "2023-05-12" },
      { start: "2023-05-22", end: "2023-05-26" },
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-09-04", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
    ],
  },

  {
    name: "GeP 23-4 F",
    blocks: [
      { start: "2023-02-01", end: "2023-03-03" },
      { start: "2023-04-24", end: "2023-05-12" },
      { start: "2023-05-22", end: "2023-05-26" },
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-09-04", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
    ],
  },
  {
    name: "GeP 23-5 F",
    blocks: [
      { start: "2023-02-01", end: "2023-03-03" },
      { start: "2023-04-24", end: "2023-05-12" },
      { start: "2023-05-22", end: "2023-05-26" },
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-09-04", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
    ],
  },

  {
    name: "GeP 23-6 F",
    blocks: [
      { start: "2023-02-01", end: "2023-02-10" },
      { start: "2023-03-27", end: "2023-04-21" },
      { start: "2023-05-30", end: "2023-07-06" },
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
    ],
  },

  {
    name: "GeP 23-7 S",
    blocks: [
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
    ],
  },

  {
    name: "GeP 23-8 S",
    blocks: [
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
    ],
  },

  {
    name: "GeP 23-9 S",
    blocks: [
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-04-30" },
      { start: "2024-05-02", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
    ],
  },

  {
    name: "GeP 23-10 S",
    blocks: [
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
    ],
  },

  {
    name: "GeP 23-11 S",
    blocks: [
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
    ],
  },

  {
    name: "GeP 23-12 S",
    blocks: [
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-04-30" },
      { start: "2024-05-02", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
    ],
  },

  {
    name: "GeP 24-3 F",
    blocks: [
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-05-26", end: "2026-05-29" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
      { start: "2026-09-28", end: "2026-10-16" },
      { start: "2026-11-02", end: "2026-11-13" },
      { start: "2027-01-04", end: "2027-01-28" },
    ],
  },

  {
    name: "GeP 24-4 F",
    blocks: [
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-04-30" },
      { start: "2024-05-02", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
      { start: "2026-08-20", end: "2026-09-25" },
      { start: "2026-11-23", end: "2026-12-18" },
    ],
  },

  {
    name: "GeP 24-5 F",
    blocks: [
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-05-26", end: "2026-05-29" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
      { start: "2026-09-28", end: "2026-10-16" },
      { start: "2026-11-02", end: "2026-11-13" },
      { start: "2027-01-04", end: "2027-01-28" },
    ],
  },

  {
    name: "GeP 24-6 S",
    blocks: [
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
      { start: "2026-08-20", end: "2026-09-25" },
      { start: "2026-11-23", end: "2026-12-18" },
      { start: "2027-02-01", end: "2027-02-26" },
      { start: "2027-04-19", end: "2027-05-05" },
      { start: "2027-05-18", end: "2027-05-28" },
    ],
  },

  {
    name: "GeP 24-7 S",
    blocks: [
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
      { start: "2026-08-20", end: "2026-09-25" },
      { start: "2026-11-23", end: "2026-12-18" },
      { start: "2027-02-01", end: "2027-02-26" },
      { start: "2027-04-19", end: "2027-05-05" },
      { start: "2027-05-18", end: "2027-05-28" },
    ],
  },

  {
    name: "GeP 24-8 S",
    blocks: [
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-05-26", end: "2026-05-29" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
      { start: "2026-09-28", end: "2026-10-16" },
      { start: "2026-11-02", end: "2026-11-13" },
      { start: "2027-01-04", end: "2027-01-28" },
      { start: "2027-03-15", end: "2027-03-25" },
      { start: "2027-03-30", end: "2027-04-16" },
      { start: "2027-05-31", end: "2027-06-30" },
    ],
  },

  {
    name: "GeP 24-9 S",
    blocks: [
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-05-26", end: "2026-05-29" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
      { start: "2026-09-28", end: "2026-10-16" },
      { start: "2026-11-02", end: "2026-11-13" },
      { start: "2027-01-04", end: "2027-01-28" },
      { start: "2027-03-15", end: "2027-03-25" },
      { start: "2027-03-30", end: "2027-04-16" },
      { start: "2027-05-31", end: "2027-06-30" },
    ],
  },

  {
    name: "GeP 24-10 S",
    blocks: [
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
      { start: "2026-08-20", end: "2026-09-25" },
      { start: "2026-11-23", end: "2026-12-18" },
      { start: "2027-02-01", end: "2027-02-26" },
      { start: "2027-04-19", end: "2027-05-05" },
      { start: "2027-05-18", end: "2027-05-28" },
    ],
  },
  {
    name: "GeP 25-3 F",
    blocks: [
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
      { start: "2026-08-20", end: "2026-09-25" },
      { start: "2026-11-23", end: "2026-12-18" },
      { start: "2027-02-01", end: "2027-02-26" },
      { start: "2027-04-19", end: "2027-05-05" },
      { start: "2027-05-18", end: "2027-05-28" },
    ],
  },
  {
    name: "GeP 25-4 F",
    blocks: [
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-02" },
      { start: "2026-04-07", end: "2026-04-10" },
      { start: "2026-05-26", end: "2026-05-29" },
      { start: "2026-06-01", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
      { start: "2026-09-28", end: "2026-10-16" },
      { start: "2026-11-02", end: "2026-11-13" },
      { start: "2027-01-04", end: "2027-01-28" },
      { start: "2027-03-15", end: "2027-03-25" },
      { start: "2027-03-30", end: "2027-04-16" },
      { start: "2027-05-31", end: "2027-06-30" },
    ],
  },
  {
    name: "GeP 25-5 F",
    blocks: [
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-04-30" },
      { start: "2026-05-04", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
      { start: "2026-08-20", end: "2026-09-25" },
      { start: "2026-11-23", end: "2026-12-18" },
      { start: "2027-02-01", end: "2027-02-26" },
      { start: "2027-04-19", end: "2027-05-05" },
      { start: "2027-05-18", end: "2027-05-28" },
    ],
  },
];

let twoYearsTainingClasses = [
  {
    name: "GeP 23-1 FV",
    blocks: [
      { start: "2023-02-01", end: "2023-02-10" },
      { start: "2023-03-27", end: "2023-04-21" },
      { start: "2023-05-30", end: "2023-07-06" },
      { start: "2023-09-18", end: "2023-09-29" },
      { start: "2023-10-04", end: "2023-10-13" },
      { start: "2023-11-27", end: "2023-12-20" },
      { start: "2024-01-08", end: "2024-01-12" },
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
    ],
  },
  {
    name: "GeP 23-2 FV",
    blocks: [
      { start: "2023-02-01", end: "2023-03-03" },
      { start: "2023-04-24", end: "2023-05-12" },
      { start: "2023-05-22", end: "2023-05-26" },
      { start: "2023-08-24", end: "2023-09-15" },
      { start: "2023-09-04", end: "2023-09-15" },
      { start: "2023-11-06", end: "2023-11-24" },
      { start: "2024-01-15", end: "2024-01-31" },
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
    ],
  },

  {
    name: "GeP 24-1 FV",
    blocks: [
      { start: "2024-02-01", end: "2024-02-01" },
      { start: "2024-02-05", end: "2024-02-23" },
      { start: "2024-04-22", end: "2024-04-30" },
      { start: "2024-05-02", end: "2024-05-08" },
      { start: "2024-05-13", end: "2024-05-17" },
      { start: "2024-05-27", end: "2024-06-07" },
      { start: "2024-08-29", end: "2024-09-27" },
      { start: "2024-11-18", end: "2024-11-29" },
      { start: "2024-12-09", end: "2024-12-19" },
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
    ],
  },

  {
    name: "GeP 24-2 FV",
    blocks: [
      { start: "2024-02-26", end: "2024-03-15" },
      { start: "2024-04-08", end: "2024-04-19" },
      { start: "2024-06-10", end: "2024-07-05" },
      { start: "2024-09-30", end: "2024-10-02" },
      { start: "2024-10-07", end: "2024-10-18" },
      { start: "2024-11-04", end: "2024-11-15" },
      { start: "2025-01-06", end: "2025-01-30" },
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
    ],
  },
  {
    name: "GeP 25-1 FV",
    blocks: [
      { start: "2025-03-24", end: "2025-04-17" },
      { start: "2025-06-10", end: "2025-07-23" },
      { start: "2025-10-06", end: "2025-10-17" },
      { start: "2025-11-03", end: "2025-11-14" },
      { start: "2026-01-05", end: "2026-01-29" },
      { start: "2026-03-16", end: "2026-04-10" },
      { start: "2026-05-26", end: "2026-06-19" },
      { start: "2026-06-29", end: "2026-07-08" },
      { start: "2026-09-28", end: "2026-10-16" },
      { start: "2026-11-02", end: "2026-11-13" },
      { start: "2027-01-04", end: "2027-01-28" },
      { start: "2027-03-15", end: "2027-04-16" },
      { start: "2027-05-31", end: "2027-06-30" },
    ],
  },
  {
    name: "GeP 25-2 FV",
    blocks: [
      { start: "2025-02-03", end: "2025-03-07" },
      { start: "2025-04-22", end: "2025-04-30" },
      { start: "2025-05-05", end: "2025-05-23" },
      { start: "2025-09-04", end: "2025-10-02" },
      { start: "2025-11-17", end: "2025-12-16" },
      { start: "2026-02-02", end: "2026-02-27" },
      { start: "2026-04-13", end: "2026-05-08" },
      { start: "2026-05-18", end: "2026-05-22" },
      { start: "2026-08-20", end: "2026-09-25" },
      { start: "2026-11-23", end: "2026-12-18" },
      { start: "2027-02-01", end: "2027-02-26" },
      { start: "2027-04-19", end: "2027-05-05" },
      { start: "2027-05-18", end: "2027-05-28" },
    ],
  },
];

export default {
  getThreeYearsTrainingClasses() {
    return classes;
  },
  getThreeYearsClassBlocks(className) {
    if (classes.find((item) => item.name === className) !== undefined) {
      return classes.find((item) => item.name === className).blocks;
    } else {
      return twoYearsTainingClasses.find((item) => item.name === className)
        .blocks;
    }
  },
  getTwoYearsTrainingClasses() {
    return twoYearsTainingClasses;
  },
  getTwoYearsClassBlocks(className) {
    return twoYearsTainingClasses.find((item) => item.name === className)
      .blocks;
  },
};
